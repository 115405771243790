import { Component, Vue, Watch } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';
import { getStatusLabel } from '@/support/ReportStatus';

@Component<Bezwaar>({})
export default class Bezwaar extends Vue {
  public $pageTitle = 'Bezwaar';

  protected isLoading = true;

  protected items: SidemenuItem[] = [
    {
      title: 'Mededelingen',
      link: '/bezwaar/',
      icon: {
        key: 'notification_important',
      },
    },
  ];

  protected bezwaar: SidemenuItem[] = [
    {
      title: 'Dashboard BAC',
      link: '/bezwaar/bac-dashboard',
      icon: {
        key: 'dashboard',
      },
    },
    {
      title: getStatusLabel('objection_created'),
      link: '/bezwaar/reports?section=bac&status=objection_created',
      icon: {
        key: 'keyboard',
      },
    },
    {
      title: getStatusLabel('objection_werkvoorbereiding_rejected'),
      link: '/bezwaar/reports?section=bac&status=objection_werkvoorbereiding_rejected',
      icon: {
        key: 'reply_all',
      },
    },
    {
      title: getStatusLabel('objection_approved'),
      link: '/bezwaar/reports?section=bac&status=objection_approved',
      icon: {
        key: 'done',
      },
    },
    {
      title: getStatusLabel('objection_corrected'),
      link: '/bezwaar/reports?section=bac&status=objection_corrected',
      icon: {
        key: 'add_comment',
      },
    },
    {
      title: getStatusLabel('objection_pending_close'),
      link: '/bezwaar/reports?section=bac&status=objection_pending_close',
      icon: {
        key: 'done_all',
      },
    },
    {
      title: getStatusLabel('objection_closed'),
      link: '/bezwaar/reports?section=bac&status=objection_closed',
      icon: {
        key: 'move_to_inbox',
      },
    },
  ];

  protected ambtelijkBezwaar: SidemenuItem[] = [
    {
      title: 'Dashboard Ambtelijk',
      link: '/bezwaar/ambtelijk-dashboard',
      icon: {
        key: 'dashboard',
      },
    },
    {
      title: getStatusLabel('objection_created'),
      link: '/bezwaar/reports?section=ambtelijk&status=objection_created',
      icon: {
        key: 'keyboard',
      },
    },
    {
      title: getStatusLabel('objection_werkvoorbereiding_rejected'),
      link: '/bezwaar/reports?section=ambtelijk&status=objection_werkvoorbereiding_rejected',
      icon: {
        key: 'reply_all',
      },
    },
    {
      title: getStatusLabel('objection_approved'),
      link: '/bezwaar/reports?section=ambtelijk&status=objection_approved',
      icon: {
        key: 'done',
      },
    },
    {
      title: getStatusLabel('objection_corrected'),
      link: '/bezwaar/reports?section=ambtelijk&status=objection_corrected',
      icon: {
        key: 'add_comment',
      },
    },
    {
      title: getStatusLabel('objection_pending_close'),
      link: '/bezwaar/reports?section=ambtelijk&status=objection_pending_close',
      icon: {
        key: 'done_all',
      },
    },
    {
      title: getStatusLabel('objection_closed'),
      link: '/bezwaar/reports?section=ambtelijk&status=objection_closed',
      icon: {
        key: 'move_to_inbox',
      },
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Bezwaar' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
